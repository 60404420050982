import React, { SyntheticEvent, useEffect, useState } from 'react'
import {
  ClientDetailsPaper,
  StylingAsterisk,
  ClientDetailsTheme,
  StyledStack,
  ClientInfoLabel,
  StyledCancelButton,
  StyledAddButton,
  MainStack,
  SubStack1,
  SubStack1_2,
  OutlinedInputSX,
  CompanyLogoStackSX,
  DragDropStack,
  AssignedModuleStack,
  SubModuleCard,
  AdminListMainStack,
  DialogStack,
  DialogContentStack,
  DialogActionSX,
  CompanyNameStack,
  AdminListStack,
  AdminTableSX,
  errorSx,
  AdminListTableStyles,
  alignFlexStart,
} from './ClientsStyles'
import {
  Typography,
  ThemeProvider,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@mui/material'
import OutlinedInput from '@mui/material/OutlinedInput'
import FormControl from '@mui/material/FormControl'
import { toast } from 'react-toastify'
import {
  AddSubModuleCard,
  FullWidthStack,
  StyledAddRoundedIcon,
  StyledContainedButton,
  StyledTableMessage,
} from '../Common/CommonStyles'
import SubModuleCards from '../Common/SubModuleCard'
import StyledBreadCrumb from '../Common/StyledBreadCrumb'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { DndProvider } from 'react-dnd'
import DragDrop from '../Common/DragDrop'
import StyledTable from '../Common/StyledTable'
import AddIcon from '@mui/icons-material/Add'
import { connect } from 'react-redux'
import { clientManagementEntity, clientManagementUI, userManagementUI } from '../../reducers'
import { RootState } from '../../configureStore'
import {
  clientAssignModules,
  clientDataFlag,
  clientInfo,
  createClient,
  editClient,
  featureIdInfo,
  resendPasswordReset,
  updateClient,
} from '../../actions'
import { Dispatch } from 'redux'
import PropTypes from 'prop-types'
import {
  clientInfoType,
  createClientsType,
  editDeleteClientRequestType,
  modulesType,
  userDTOStype,
} from '../../actions/Types'
import { getAssignedModules } from '../../actions'
import { setLimit, NameLimit, DesignationLimit, EmailLimit, CompanyNameLimit } from '../../utils'

type clientDetails = {
  createClientData: (data: createClientsType) => void
  addClientStatusDone: boolean
  getSelectedModules: modulesType[]
  clientInfo: clientInfoType['data']
  setClientInfo: (data: clientInfoType['data']) => void
  resetClientInfo: () => void
  resetAssignedModule: () => void
  addModules: (data: modulesType[]) => void
  getClientDetails: (data: editDeleteClientRequestType) => void
  clientDetailsReset: () => void
  clientEditFetched: boolean
  mergSelectedModules: (data: modulesType[]) => void
  clientfetchSuccess: () => void
  clientfetchReset: () => void
  clientDataFetchStatus: boolean
  updateClient: (data: createClientsType) => void
  clientEditInfo: any
  updateClientStatus: boolean
  resetCreateClient: () => void
  resetUpdateClient: () => void
  removeAssignedModule: (module: modulesType) => void
  featureIdInfo: string[]
  resetFeatureIdInfo: () => void
  setFeatureIdInfo: (data: string[]) => void
  resendPasswordReset: (data: object) => void
  resendPassword: boolean
  resetResendPasswordReset:() => void
}
/* eslint-disable */

const ClientDetails = (props: clientDetails) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const {
    createClientData,
    addClientStatusDone,
    getSelectedModules,
    clientInfo,
    setClientInfo,
    resetClientInfo,
    resetAssignedModule,
    addModules,
    getClientDetails,
    clientEditInfo,
    clientDetailsReset,
    clientEditFetched,
    mergSelectedModules,
    clientfetchSuccess,
    clientfetchReset,
    clientDataFetchStatus,
    updateClient,
    updateClientStatus,
    resetCreateClient,
    resetUpdateClient,
    removeAssignedModule,
    featureIdInfo,
    resetFeatureIdInfo,
    setFeatureIdInfo,
    resendPasswordReset,
    resendPassword,
    resetResendPasswordReset,
  } = props
  const [open, setOpen] = React.useState(false)
  const [companyName, setCompanyName] = useState(clientInfo.companyName)
  const [designation, setDesignation] = useState('')
  const [email, setEmail] = useState('')
  const [name, setName] = useState('')
  const [clientId] = useState(localStorage.getItem('clientId'))
  const [error, setError] = useState('')
  const [logoImgPath, setLogoImgPath] = useState('')
  const [validEmailMessage, setValidEmailMessage] = useState('')
  const [adminList, setAdminList] = useState<
    | {
        name: string
        email: string
        designation: string
      }[]
    | []
  >(clientInfo.adminList)

  const [userDTO, setUserDTO] = useState<userDTOStype[] | []>(clientInfo.userDTO)
  useEffect(() => {
    setClientInfo({ ...clientInfo, adminList: adminList, userDTO: userDTO })
  }, [adminList, userDTO])
  useEffect(() => {
    setAdminList(clientInfo.adminList)
    setUserDTO(clientInfo.userDTO)
    setLogoImgPath(clientInfo.logoImagePath)
  }, [clientInfo])
  useEffect(() => {
    const id = localStorage.getItem('clientId')
    if (id) {
      // if (clientDataFetchStatus) {
      getClientDetails({
        id: id,
      })
      // }
    }
  }, [])
  useEffect(() => {
    if (addClientStatusDone) {
      resetCreateClient()
      navigate('/clients')
      toast.success(t('add_client_success_message'))
    }
    if (updateClientStatus) {
      resetUpdateClient()
      clientfetchReset()
      navigate('/clients')
      toast.success(t('update_client_success_message'))
    }
  }, [addClientStatusDone, updateClientStatus])
  useEffect(() => {
    if (clientEditFetched) {
      if (clientDataFetchStatus) {
        setClientInfo({
          ...clientInfo,
          logoImagePath: clientEditInfo.logoImagePath,
          companyName: clientEditInfo.name,
          adminList: clientEditInfo.users?.map(
            (user: { firstName: string; email: string; designation: string }) => {
              return { name: user.firstName, email: user.email, designation: user.designation }
            },
          ),
          userDTO: clientEditInfo.userDTOS,
          id: clientEditInfo.id,
          code: clientEditInfo.code,
        })
        mergSelectedModules(clientEditInfo.modules)
        setCompanyName(clientEditInfo.name)
        setFeatureIdInfo(clientEditInfo?.featureClientIds?.map(String))
        clientfetchSuccess()
      }
    }
  }, [clientEditFetched])
  useEffect(() => {
    const handleRefresh = (event: any) => {
      event.preventDefault()
      event.returnValue = '' // Required for Chrome
    }
    window.addEventListener('beforeunload', handleRefresh)
    return () => {
      window.removeEventListener('beforeunload', handleRefresh)
    }
  }, [])

  useEffect(() => {
    if (resendPassword) {
      toast.success(t('reset_password_message'))
      resetResendPasswordReset()
    }
  }, [resendPassword, resetResendPasswordReset])

  //open pop up
  const handleClickOpen = () => {
    setOpen(true)
  }

  //Checking valid email
  const isValidEmail = (email: string): boolean => {
    const emailRegex: RegExp = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/
    return emailRegex.test(email)
  }

  //close pop up
  const handleClose = () => {
    setOpen(false)
    setEmail('')
    setName('')
    setDesignation('')
    setError('')
    setValidEmailMessage('')
  }

  //Handle file operation related to drag and drop and selecting file for client cover image
  const handleDrop = (data: string): void => {
    setClientInfo({ ...clientInfo, logoImagePath: data })
  }

  //handle Assign module on click
  const handleAssignModule = () => {
    addModules(getSelectedModules)
    navigate(`assignmodule`)
  }

  //Setting Company Name in state
  const handleCompanyName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = setLimit(event, CompanyNameLimit)
    setCompanyName(value)
    setClientInfo({ ...clientInfo, companyName: value })
  }

  //Setting Admin Designation in state
  const handleDesignation: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = setLimit(event, DesignationLimit)
    setDesignation(value)
  }

  //Setting Admin Email in state
  const handleEmail: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = setLimit(event, EmailLimit)
    setEmail(value)
    if (isValidEmail(event.target.value)) {
      setValidEmailMessage('')
    } else setValidEmailMessage(`${t('valid_email')}`)
  }

  //Setting Admin Name in state
  const handleName: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    const value = setLimit(event, NameLimit)
    setName(value)
  }

  //Adding data to the admin table
  const handleAddAdmin = () => {
    setAdminList((admin) => {
      return [
        ...admin,
        {
          name: name,
          email: email,
          designation: designation,
        },
      ]
    })
    setUserDTO((adminDetails) => [
      ...adminDetails,
      {
        firstName: name,
        email: email,
        designation: designation,
        lastName: '',

        appUserRoles: [
          {
            roleId: 2,
          },
        ],
      },
    ])
    setOpen(false)
    setEmail('')
    setName('')
    setDesignation('')
  }

  //Handle On click cross button
  const handleCrossButton = (module: modulesType) => {
    removeAssignedModule(module)
  }

  //Verifying details
  const verifyDetails = (): boolean => {
    if (clientInfo.companyName.trim().length === 0) {
      toast.error(t('company_name_error'))
      return false
    } else if (clientInfo.userDTO.length === 0) {
      toast.error(t('admin_error'))
      return false
    } else if (getSelectedModules.length === 0) {
      toast.error(t('module_error'))
      return false
    }
    return true
  }

  //Creating client
  const createClient = () => {
    if (!verifyDetails()) {
      return
    } else if (clientId) {
      updateClient({
        name: clientInfo.companyName,
        logoImagePath: clientInfo.logoImagePath,
        userDTOS: clientInfo.userDTO,
        modules: getSelectedModules,
        id: clientInfo.id,
        code: clientInfo.code,
        featureClientIds: featureIdInfo?.map(Number),
      })
    } else {
      createClientData({
        name: clientInfo.companyName,
        logoImagePath: clientInfo.logoImagePath,
        userDTOS: clientInfo.userDTO,
        modules: getSelectedModules,
        featureClientIds: featureIdInfo?.map(Number),
      })
    }
  }

  //Back Button clicked
  const backButtonClick = () => {
    localStorage.removeItem('clientId')
    resetClientInfo()
    resetAssignedModule()
    clientDetailsReset()
    clientfetchReset()
    resetFeatureIdInfo()
  }

  const handleResetPass = (email: string) => {
    resendPasswordReset({ email: email })
  }

  const handleEdit = ()=>{
    // navigate(`/clients/details/assignmodule?code=${clientInfo.code}`)
    navigate(`/clients/details/assignmodule#editClientDetails`)
  }

  return (
    <ClientDetailsPaper elevation={0}>
      <StyledBreadCrumb handleDone={createClient} handleBack={backButtonClick} />
      <Stack direction={'row'} sx={MainStack} spacing={3}>
        <Stack direction={'column'} spacing={3} sx={SubStack1}>
          <Stack sx={SubStack1_2} spacing={4}>
            <Stack spacing={0.5} direction={'column'} sx={CompanyNameStack}>
              <Typography sx={ClientInfoLabel}>
                {t('company_name')}
                <StylingAsterisk>*</StylingAsterisk>
              </Typography>
              <FormControl>
                <OutlinedInput
                  size='small'
                  placeholder={`${t('enter_company_name')}`}
                  sx={OutlinedInputSX}
                  value={clientInfo.companyName}
                  onChange={handleCompanyName}
                />
              </FormControl>
            </Stack>
            <Stack spacing={0.5} direction={'column'} sx={CompanyLogoStackSX}>
              <Typography sx={ClientInfoLabel}>
                {t('company_logo')}
                <Typography component={'span'} color={'gray'} fontSize={'10px'} ml={'5px'}>
                  {t('logo_error')}
                </Typography>
              </Typography>
              <Stack sx={DragDropStack}>
                <DndProvider backend={HTML5Backend}>
                  <DragDrop onDrop={handleDrop} coverImageUrl={clientInfo.logoImagePath} />
                </DndProvider>
              </Stack>
            </Stack>
          </Stack>
          <Stack sx={AssignedModuleStack} direction={'column'} spacing={1}>
            <Typography sx={ClientInfoLabel}>
              {t('assigned_modules')}
              <StylingAsterisk>*</StylingAsterisk>
            </Typography>
            <StyledStack>
              {getSelectedModules.length !== 0 &&
                getSelectedModules?.map((module, index) => {
                  return (
                    <SubModuleCards
                      key={index}
                      cardData={module}
                      crossIcon={true}
                      onClickCrossButton={() => {
                        handleCrossButton(module)
                      }}
                      handleEdit={handleEdit}
                      includeButton={true}
                      backgroundimg={module.logoImagePath}
                      isEditPage = {true}
                    />
                  )
                })}
              <AddSubModuleCard onClick={handleAssignModule} sx={SubModuleCard}>
                <AddIcon />
                <Typography>{t('add_new')}</Typography>
              </AddSubModuleCard>
            </StyledStack>
          </Stack>
        </Stack>
        <Stack direction={'column'} spacing={2.5} sx={AdminListMainStack}>
          <Stack direction={'row'} sx={AdminListStack} width={'100%'}>
            <Stack direction={'row'}>
              <Typography sx={ClientInfoLabel}>
                {t('admin_list')}
                <StylingAsterisk>*</StylingAsterisk>
              </Typography>
            </Stack>
            <ThemeProvider theme={ClientDetailsTheme}>
              <StyledContainedButton startIcon={<StyledAddRoundedIcon />} onClick={handleClickOpen}>
                {t('add_admin')}
              </StyledContainedButton>
            </ThemeProvider>
          </Stack>
          {clientInfo.adminList.length ? (
            <StyledTable
              tableData={clientInfo.adminList}
              styles={AdminListTableStyles}
              handleResetPass={handleResetPass}
              actions={['reset-link']}
            ></StyledTable>
          ) : (
            <FullWidthStack sx={AdminTableSX}>
              <StyledTableMessage>{t('please_add_at_least_one_admin')}</StyledTableMessage>
            </FullWidthStack>
          )}
        </Stack>
      </Stack>
      <Dialog
        open={open}
        aria-labelledby='alert-dialog-title'
        aria-describedby='alert-dialog-description'
        sx={DialogStack}
      >
        <DialogTitle id='alert-dialog-title' alignSelf={'center'}>
          {t('add_admin_details')}
        </DialogTitle>
        <DialogContent>
          <Stack direction={'column'} spacing={2} sx={DialogContentStack}>
            <Stack direction={'row'} spacing={3} sx={alignFlexStart}>
              <Stack spacing={0.5} direction={'column'} sx={DialogContentStack}>
                <Typography sx={ClientInfoLabel}>
                  {t('Name')}
                  <StylingAsterisk>*</StylingAsterisk>
                </Typography>
                <FormControl>
                  <OutlinedInput
                    size='small'
                    placeholder={`${t('enter_name')}`}
                    sx={OutlinedInputSX}
                    onChange={handleName}
                  />
                </FormControl>
              </Stack>
              <Stack spacing={0.5} direction={'column'} sx={DialogContentStack}>
                <Typography sx={ClientInfoLabel}>
                  {t('email_id')}
                  <StylingAsterisk>*</StylingAsterisk>
                </Typography>
                <FormControl>
                  <OutlinedInput
                    size='small'
                    placeholder={`${t('enter_email_address')}`}
                    sx={OutlinedInputSX}
                    onChange={handleEmail}
                  />
                  <Typography variant='caption' sx={errorSx}>
                    {validEmailMessage}
                  </Typography>
                </FormControl>
              </Stack>
            </Stack>
            <Stack direction={'row'} spacing={3} sx={alignFlexStart}>
              <Stack spacing={0.5} direction={'column'} sx={DialogContentStack}>
                <Typography sx={ClientInfoLabel}>
                  {t('designation')}
                  <StylingAsterisk>*</StylingAsterisk>
                </Typography>
                <FormControl>
                  <OutlinedInput
                    size='small'
                    placeholder={`${t('enter_designation')}`}
                    sx={OutlinedInputSX}
                    onChange={handleDesignation}
                  />
                </FormControl>
              </Stack>
            </Stack>
          </Stack>
        </DialogContent>
        <DialogActions sx={DialogActionSX}>
          <StyledAddButton
            onClick={handleAddAdmin}
            disabled={
              !Boolean(name.length) ||
              !Boolean(designation.length) ||
              !Boolean(email.length) ||
              validEmailMessage.length > 0
            }
            size='small'
          >
            {t('add')}
          </StyledAddButton>
          <StyledCancelButton variant='outlined' size='small' onClick={handleClose}>
            {t('cancel')}
          </StyledCancelButton>
        </DialogActions>
      </Dialog>
    </ClientDetailsPaper>
  )
}

ClientDetails.prototype = {
  createClientData: PropTypes.func.isRequired,
}

const mapStateToProps = (state: RootState) => {
  return {
    AddedClientInfo: clientManagementEntity.getClientManagementData(state).createClients,
    addClientStatusDone: clientManagementUI.getClientManagementData(state).createClients,
    AddClientPass: clientManagementUI.getClientManagementData(state).createClients,
    getSelectedModules: clientManagementEntity.getClientManagementData(state).getAssignedModules,
    clientInfo: clientManagementEntity.getClientManagementData(state).getClientInfo,
    clientEditInfo: clientManagementEntity.getClientManagementData(state).editClient,
    clientEditFetched: clientManagementUI.getClientManagementData(state).editClient,
    clientDataFetchStatus: clientManagementUI.getClientManagementData(state).clientDataStatus,
    updateClientStatus: clientManagementUI.getClientManagementData(state).updateClient,
    featureIdInfo: clientManagementEntity.getClientManagementData(state).featureIdInfo,
    resendPassword: userManagementUI.getAllUsers(state).resendPasswordReset,
  }
}
const mapDispatchToProps = (dispatch: Dispatch) => {
  return {
    createClientData: (data: createClientsType) => dispatch(createClient.request(data)),
    setClientInfo: (data: clientInfoType['data']) => dispatch(clientInfo.setClientInfo(data)),
    resetClientInfo: () => dispatch(clientInfo.resetClientInfo()),
    resetAssignedModule: () => dispatch(getAssignedModules.resetAssignedModule()),
    addModules: (data: modulesType[]) => dispatch(clientAssignModules.addModules(data)),
    getClientDetails: (data: editDeleteClientRequestType) => dispatch(editClient.request(data)),
    clientDetailsReset: () => dispatch(editClient.reset()),
    mergSelectedModules: (data: modulesType[]) =>
      dispatch(getAssignedModules.mergAssignedModule(data)),
    clientfetchSuccess: () => dispatch(clientDataFlag.success()),
    clientfetchReset: () => dispatch(clientDataFlag.reset()),
    updateClient: (data: createClientsType) => dispatch(updateClient.request(data)),
    resetCreateClient: () => dispatch(createClient.reset()),
    resetUpdateClient: () => dispatch(updateClient.reset()),
    removeAssignedModule: (module: modulesType) =>
      dispatch(getAssignedModules.removeAssignedModule(module)),
    resetFeatureIdInfo: () => dispatch(featureIdInfo.resetFeatureIds()),
    setFeatureIdInfo: (data: string[]) => dispatch(featureIdInfo.setFeatureIds(data)),
    resendPasswordReset: (data: object) => dispatch(resendPasswordReset.request(data)),
    resetResendPasswordReset: () => dispatch(resendPasswordReset.reset()),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ClientDetails)
