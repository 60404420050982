import { ACCTypes } from './Types'
export const REQUEST = 'REQUEST'
export const SUCCESS = 'SUCCESS'
export const FAILURE = 'FAILURE'
export const RESET = 'RESET'
export const ADD_ASSIGNED_MODULES = 'ADD_ASSIGNED_MODULES'
export const RESET_ASSIGNED_MODULES = 'RESET_ASSIGNED_MODULES'
export const MERG_SELECTED_MODULE = 'MERG_SELECTED_MODULE'
export const RESET_SELECTED_MODULE = 'RESET_SELECTED_MODULE'
export const REMOVE_ASSIGNED_MODULE = 'REMOVE_ASSIGNED_MODULE'
export const SET_CLINET_INFO = 'SET_CLINET_INFO'
export const RESET_CLIENT_INFO = 'RESET_CLIENT_INFO'

// This function will return Action Type object
export const createRequestTypes = (base: string) => {
  return [REQUEST, SUCCESS, FAILURE, RESET].reduce((acc: ACCTypes, type) => {
    acc[type] = `${base}_${type}`
    return acc
  }, {})
}

export const action = (type: string, payload = {}) => {
  return { type, ...payload }
}

export const GET_USER_DATA = createRequestTypes('GET_USER_DATA')

export const LOGIN = createRequestTypes('LOGIN')

export const LOGOUT = createRequestTypes('LOGOUT')

export const REFRESH_ACCESS_TOKEN = createRequestTypes('REFRESH_ACCESS_TOKEN')

export const GET_ACCESS_TOKEN = createRequestTypes('GET_ACCESS_TOKEN')

export const GET_ALL_CLIENTS = createRequestTypes('GET_ALL_CLIENTS')

export const GET_ALL_MODULES = createRequestTypes('GET_ALL_MODULES')

export const DELETE_MODULE = createRequestTypes('DELETE_MODULE')

export const CREATE_MODULE = createRequestTypes('CREATE_MODULES')

export const CREATE_SUB_MODULE = createRequestTypes('CREATE_SUB_MODULE')

export const UPLOAD_IMAGE = createRequestTypes('UPLOAD_IMAGE')

export const REMEMBER_MODULE_NAME = createRequestTypes('REMEMBER_MODULE_NAME')

export const GET_MODULE_BY_ID = createRequestTypes('GET_MODULE_BY_ID')

export const UPDATE_MODULE = createRequestTypes('UPDATE_MODULE')

export const GET_ALL_USER = createRequestTypes('GET_ALL_USER')

export const GET_USER_BY_ID = createRequestTypes('GET_USER_BY_ID')

export const ADD_NEW_USER = createRequestTypes('ADD_NEW_USER')

export const UPDATE_USER = createRequestTypes('UPDATE_USER')

export const DELETE_USER = createRequestTypes('DELETE_USER')

export const REMEMBER_USER_DETAILS = createRequestTypes('REMEMBER_USER_DETAILS')

export const CREATE_CLIENTS = createRequestTypes('CREATE_CLIENTS')

export const EDIT_CLIENT = createRequestTypes('EDIT_CLIENT')

export const DELETE_CLIENT = createRequestTypes('DELETE_CLIENT')

export const ASSIGN_MODULE = createRequestTypes('ASSIGN_MODULE')

export const ASSIGN_SUB_MODULE = createRequestTypes('ASSIGN_SUB_MODULE')

export const UPDATE_CLIENT = createRequestTypes('UPDATE_CLIENT')

export const DELETE_SUB_MODULE = createRequestTypes('DELETE_SUB_MODULE')

export const SAVE_SUB_MODULE = createRequestTypes('SAVE_SUB_MODULE')

export const UPDATE_SUB_MODULE = createRequestTypes('UPDATE_SUB_MODULE')

export const GET_CLIENT_MODULES = createRequestTypes('GET_CLIENT_MODULES')

export const SAVE_MODULE_DATA_FOR_USER = createRequestTypes('SAVE_MODULE_DATA_FOR_USER')

export const CLIENT_DATA_FETCHED = createRequestTypes('CLIENT_DATA_FETCHED')

export const GET_SUB_MODULE = createRequestTypes('GET_SUB_MODULE')

export const DELETE_USER_MODULE = createRequestTypes('DELETE_USER_MODULE')

export const DELETE_USER_CLIENT = createRequestTypes('DELETE_USER_CLIENT')

export const DELETE_USER_SUB_MODULE = createRequestTypes('DELETE_USER_SUB_MODULE')

export const GET_CLIENT_KEYS = createRequestTypes('GET_CLIENT_KEYS')

export const FORGOT_PASSWORD = createRequestTypes('FORGOT_PASSWORD')

export const RESET_PASSWORD = createRequestTypes('RESET_PASSWORD')

export const GET_CLIENT_FEATURES = createRequestTypes('GET_CLIENT_FEATURES')

export const FEATURE_IDS = createRequestTypes('FEATURE_IDS')

export const RESEND_RESET_PASSWORD_LINK = createRequestTypes('RESEND_RESET_PASSWORD_LINK')

export const GET_GRC_MODULE = createRequestTypes("GET_GRC_MODULE")