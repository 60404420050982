import {
  getClientModulesRequestType,
  IAuthData,
  RefreshAccessToken,
  assignModuleRequestType,
  assignSubModuleRequestType,
  createClientsType,
  editDeleteClientRequestType,
  getAllClientsType,
  getSubModuleRequestType,
} from '../actions/Types'
import { GetAccessToken } from '../auth/types'
import {
  DeleteModuleTypes,
  ModuleCreate,
  ModuleUpdate,
  SubModuleCardData,
  SubModuleDelete,
} from '../components/Module Management/ModuleManagementTypes'
import {
  UserBasicType,
  UserClientDeleteType,
  UserDeleteType,
  UserModuleDeleteType,
  UserSubModuleDeleteType,
  UserTypes,
} from '../components/User_Management/UserManagementTypes'
import * as RestAPI from './rest'

export const loginUserEndpoint = (data: IAuthData) => RestAPI.OAuthPOST(`oauth/token`, data)

export const logoutUserEndpoint = () => RestAPI.OAuthLogout(`oauth/logout`)

export const refreshAccessTokenEndPoint = (data: RefreshAccessToken) => {
  return RestAPI.OAuthPOST(`oauth/token`, data)
}
export const getUserDataEndPoint = () => RestAPI.OAuthGET(`oauth/app-users`)

export const getAccessTokenEndPoint = (data: GetAccessToken) =>
  RestAPI.OAuthPOST(`oauth/token`, data)

export const getAllClients = (data: getAllClientsType) => RestAPI.GET(`api/clients`, data)

export const getAllModule = () => RestAPI.GET(`/api/modules`)

export const createModuleEndPoint = (data: ModuleCreate) => RestAPI.POST(`/api/modules`, data)

export const updateModuleEndPoint = (data: ModuleUpdate) => RestAPI.PUT(`/api/modules`, data)

export const createSubModuleEndPoint = (data: any) => RestAPI.POST(`/api/sub-modules`, data)

export const deleteModuleEndPoint = (data: DeleteModuleTypes) =>
  RestAPI.DELETE(`api/modules/${data.id}`)

export const getModuleByIdEndPoint = (data: DeleteModuleTypes) =>
  RestAPI.GET(`api/modules/${data.id}`)

export const getAllUsers = () => RestAPI.GET(`/api/users/all`)

export const createUserEndPoint = (data: UserTypes) => RestAPI.POST(`/api/users`, data)

export const updateUserEndPoint = (data: UserTypes) => RestAPI.PUT(`/api/users`, data)

export const deleteUserEndPoint = (data: UserDeleteType) =>
  RestAPI.DELETE(`api/users/${data.id}/${data.clientCode}`)

export const getUserByIdEndPoint = (data: UserBasicType) => RestAPI.GET(`api/users/${data.id}`)

export const createClient = (data: createClientsType) => RestAPI.POST(`api/clients`, data)

export const uploadImage = (data: any) => RestAPI.UploadImagePOST(`/api/image/upload`, data)

export const editClient = (data: editDeleteClientRequestType) =>
  RestAPI.GET(`api/clients/${data.id}`)

export const deleteClient = (data: editDeleteClientRequestType) =>
  RestAPI.DELETE(`api/clients/${data.id}`)

export const assignModule = (data: assignModuleRequestType) =>
  RestAPI.POST(`api/clients/assign-module`, data)

export const assignSubModule = (data: assignSubModuleRequestType) =>
  RestAPI.POST(`api/clients/assign-sub-module`, data)

export const updateClient = (data: createClientsType) => RestAPI.PUT(`api/clients`, data)

export const deleteSubModuleEndPoint = (data: SubModuleDelete) =>
  RestAPI.DELETE(`api/modules/sub-modules`, data)

export const updateSubModuleEndPoint = (data: SubModuleCardData) =>
  RestAPI.PUT(`/api/sub-modules`, data)

export const getClientModules = (data: getClientModulesRequestType) =>
  RestAPI.GET(`api/clients/${data.clientId}`)

export const getSubModules = (data: getSubModuleRequestType) =>
  RestAPI.GET(`api/modules/sub-modules/${data.code}`)

export const deleteUserModule = (data: UserModuleDeleteType) =>
  RestAPI.DELETE(`api/users/module`, data)

export const deleteUserClient = (data: UserClientDeleteType) =>
  RestAPI.DELETE(`api/users/client`, data)

export const deleteUserSubModule = (data: UserSubModuleDeleteType) =>
  RestAPI.DELETE(`api/users/sub-module`, data)

export const getClientKeys = (data: object) => RestAPI.OAuthGET(`/oauth/client-keys`, data)

export const getClientFeatures = (data: { [key: string]: string }) =>
  RestAPI.OAuthGET(`/oauth/features`, data);

export const getGrcModalName = () => RestAPI.OAuthGET(`/api/modules`);

export const forgotPassword = (data: object) => RestAPI.OAuthPassword(`/api/forgot-password`, data)

export const resetPassword = (data: object) =>
  RestAPI.OAuthChangePassword(`/api/confirm-password`, data)

export const resendPasswordReset = (data: object) =>
  RestAPI.OAuthResetPassword(`/api/resend-password-reset`, data)
