import axios from 'axios'
import { isLoggedIn } from '../auth/Auth'
import { AuthBaseURL, BaseURLAdmin, getTokenFromURL } from '../utils'

const api = axios.create({
  baseURL: BaseURLAdmin,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${isLoggedIn()}`,
  },
})

export const addAuthToken = () => {
  api.defaults.headers['authorization'] = `Bearer ${isLoggedIn()}`
}

export const GET = async (url: string, params = {}) => {
  const response = await api.get(url, { params })
  return response
}

export const POST = async (url: string, data: object) => {
  const response = await api.post(url, data)
  return response
}

export const PUT = async (url: string, data: object) => {
  const response = await api.put(url, data)
  return response
}

export const DELETE = async (url: string, data?: object) => {
  const response = await api.delete(url, { data })
  return response
}

export const PATCH = async (url: string, data: object) => {
  const response = await api.patch(url, data)
  return response
}

const apiOauth = axios.create({
  baseURL: AuthBaseURL,
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
    'Acess-Control-Allow-Origin': '*',
  },
})

const apiAuth = axios.create({
  baseURL: AuthBaseURL,
  headers: {
    'Content-Type': 'application/json',
    'Access-Control-Allow-Origin': '*',
    Authorization: `Bearer ${isLoggedIn()}`,
  },
})

export const OAuthPOST = async (url: string, data: any) => {
  const response = await apiOauth.post(url, new URLSearchParams(data.userData).toString(), {
    auth: {
      username: data.clientKeys.oauthClientId,
      password: data.clientKeys.clientSecret,
    },
  })
  return response
}

export const OAuthPOSTClient = async (url: string, data: any) => {
  const response = await apiAuth.post(url, data)
  return response
}


export const OAuthLogout = async (url: string) => {
  const token = isLoggedIn()
  const response = await apiOauth.post(url, null, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}

export const OAuthGET = async (url: string, params?: object) => {
  apiOauth.defaults.headers['authorization'] = `Bearer ${isLoggedIn()}`
  const response = await apiOauth.get(url, { params })
  return response
}

const OAuthPUT = async (url: string, data: object, token: any) => {
  const response = await apiAuth.put(url, data, token)
  return response
}

const apiUploadImage = axios.create({
  baseURL: BaseURLAdmin,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Acess-Control-Allow-Origin': '*',
    Authorization: `Bearer ${isLoggedIn()}`,
  },
})

export const UploadImagePOST = async (url: string, data: any) => {
  const formData = new FormData()
  formData.append('file', data.file)
  const response = await apiUploadImage.post(url, formData)
  return response
}

export const OAuthPassword = async (url: string, data?: object) => {
  const response = await apiAuth.post(url, data)
  return response
}

export const OAuthChangePassword = async (url: string, data: object) => {
  const token = getTokenFromURL()
  const response = await OAuthPUT(url, data, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  return response
}
export const OAuthResetPassword = async (url: string, data?: object) => {
  const response = await apiAuth.post(url, data)
  return response
}
