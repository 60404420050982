import React from 'react'
import { Route, Routes } from 'react-router-dom'
import ProtectedRoute from './auth/ProtectedRoute'
import Login from './components/Pages/Login'
import { Box } from '@mui/material'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import ModuleManagement from './components/Module Management/ModuleManagement'
import AddNewModule from './components/Module Management/AddNewModule'
import ClientManagement from './components/Clients/ClientManagement'
import ClientDetails from './components/Clients/ClientDetails'
import AddNewSubModule from './components/Module Management/AddNewSubModule'
import '../src/style.css'
import AssignModule from './components/Clients/AssignModule'
import UserManagement from './components/User_Management/UserManagement'
import AddNewUser from './components/User_Management/AddNewUser'
import AssignSubModule from './components/Clients/AssignSubModule'
import NotFound from './components/Pages/NotFound'
import AssignModuleClient from './components/User_Management/AssignModuleClient'
import AssignSubModuleUser from './components/User_Management/AssignSubModuleUser'
import ForgotPassword from './components/Pages/ForgotPassword'
import ChangePassword from './components/Pages/ChangePassword'
/* eslint-disable */

function App() {
  return (
    <Box width={'100%'}>
      <Routes>
        <Route path='/' element={<Login />} />
        <Route path='/forgot-password' element={<ForgotPassword />} />
        <Route path='/change-password' element={<ChangePassword />} />
        <Route path='/module'>
          <Route path='' element={<ProtectedRoute component={<ModuleManagement />} />} />
          <Route path='addmodule'>
            <Route path='' element={<ProtectedRoute component={<AddNewModule />} />} />
            <Route
              path='addsubmodule'
              element={<ProtectedRoute component={<AddNewSubModule />} />}
            />
          </Route>
          <Route path=':b'>
            <Route path='' element={<ProtectedRoute component={<AddNewModule />} />} />
            <Route
              path='addsubmodule'
              element={<ProtectedRoute component={<AddNewSubModule />} />}
            />
            <Route path=':b' element={<ProtectedRoute component={<AddNewSubModule />} />} />
          </Route>
        </Route>
        <Route path='/clients'>
          <Route path='' element={<ProtectedRoute component={<ClientManagement />} />} />
          <Route path='details'>
            <Route path='' element={<ProtectedRoute component={<ClientDetails />} />} />
            <Route path='assignmodule'>
              <Route path='' element={<ProtectedRoute component={<AssignModule />} />} />
              <Route
                path='assignsubmodule'
                element={<ProtectedRoute component={<AssignSubModule />} />}
              ></Route>
            </Route>
          </Route>
        </Route>
        <Route path='/users'>
          <Route path='' element={<ProtectedRoute component={<UserManagement />} />} />
          <Route path='adduser'>
            <Route path='' element={<ProtectedRoute component={<AddNewUser />} />} />
            <Route path='assignmodule'>
              <Route path='' element={<ProtectedRoute component={<AssignModuleClient />} />} />
              <Route
                path='assignsubmodule'
                element={<ProtectedRoute component={<AssignSubModule />} />}
              />
            </Route>
          </Route>
          <Route path='edituser'>
            <Route path='' element={<ProtectedRoute component={<AddNewUser />} />} />
            <Route path='assignmodule'>
              <Route path='' element={<ProtectedRoute component={<AssignModuleClient />} />} />
            </Route>
          </Route>
        </Route>
        <Route path='*' element={<NotFound />} />
      </Routes>
      <ToastContainer theme='colored' position='top-right' limit={1} />
    </Box>
  )
}

export default App
