import { toast } from 'react-toastify'
import { put } from 'redux-saga/effects'
import { actionTypes } from '../actions'
import { addAuthToken } from '../services/rest'
const { SUCCESS, FAILURE } = actionTypes

export function* sendPayload(apiResponse: any, event: { [x: string]: any }) {
  if (
    (event[SUCCESS] === 'LOGIN_SUCCESS' || event[SUCCESS] === 'GET_ACCESS_TOKEN_SUCCESS') &&
    apiResponse.data.exchange_token
  ) {
    localStorage.setItem('token', apiResponse.data.exchange_token)
    localStorage.setItem('refresh_token', apiResponse.data.refresh_token)
    let expireTime = apiResponse.data.expires_in
    localStorage.setItem('token_expire_time', JSON.stringify([Date.now(), expireTime]))
    addAuthToken()
    setTimeout(() => {
      const event = new CustomEvent('Access_Token_Event', {
        detail: { message: 'Please request for access token.' },
      })
      document.dispatchEvent(event)
    }, expireTime * 1000)
  }
  yield put({
    type: apiResponse ? event[SUCCESS] : event[FAILURE],
    payload: apiResponse ? (apiResponse ? apiResponse.data : apiResponse.data.error) : {},
  })
}

export function* sendPayloadFailure(error: any, event: { [x: string]: any }) {
  if (error?.response?.status === 401) {
    toast.error(error.response.data.message ?? 'Unauthorised User')

    // localStorage.clear()
    window.location.href = '/'
  }
  if (error?.response) {
    toast.error(error?.response?.data?.detail ?? error?.response?.data.message)

    yield put({
      type: event[FAILURE],
      payload: error.response ? error.response : {},
    })
  } else {
    if (error.error) {
      yield put({
        type: event[FAILURE],
        payload: error.error,
      })
    } else {
      toast.error('Something went wrong')
      setTimeout(function () {
        window.location.reload()
      }, 4000)
    }
  }
}
